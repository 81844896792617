* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  height: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: black;
}
a:visited {
  text-decoration: none;
  color: black;
}

/* -------------------------------- Header CSS --------------------------- */

h1 {
  padding: 30px 30px;
  font-size: 2em;
}

h2 {
  font-size: 3em;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  margin: auto;
  /* height: 10%; */
  position: fixed;
  top: 0;
  background-color: aliceblue;
}

.nav a {
  float: right;
  padding: 30px 30px;
  font-size: larger;
  font-weight: bold;
}

.nav a:hover {
  text-decoration: #2541b2 underline 3px;
}

#header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 18% auto 13% auto;
  flex-wrap: wrap;
  width: 100%;
}

#header div {
  width: 35%;
  padding: 0px 20px;
}

#header p {
  font-size: 1.3em;
  line-height: 1.5em;
}

#header ul a {
  display: flex;
  list-style: none;
  padding: 40px 70px 0px 0px;
  margin-top: 30%;
}

#header .button {
  padding: 15px 30px;
  font-size: 1.4em;
  background-color: #2541b2;
  color: white;
  border: none;
  border-radius: 0.5em;
  margin-top: 20px;
  display: block;
  width: 60%;
  text-align: center;
}
#header .button:hover {
  text-decoration: underline;
}

img {
  width: 25%;
}

@media screen and (max-width: 680px) {
  h1 {
    margin: 0;
    display: block;
    text-align: center;
  }

  .nav {
    display: block;
    text-align: center;
  }

  .nav-links {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
  }

  .nav a {
    padding: 5px;
    float: none;
  }

  .nav a:first-child {
    display: none;
  }

  #header {
    margin: 3% auto;
  }

  #header ul {
    margin-bottom: 3%;
    display: flex;
  }

  #header ul a {
    padding: 40px;
  }

  #header div {
    width: 90%;
  }

  #header img {
    display: none;
  }

  #header .button {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .nav {
    justify-content: center;
  }
  .nav-links {
    width: 90%;
    margin: auto;
  }

  .nav a {
    float: none;
  }

  #header {
    margin: 30px auto;
  }

  #header ul {
    display: flex;
    margin: 3% auto;
  }

  #header ul a {
    font-size: x-large;
    padding: 40px;
  }

  #header div {
    margin: 30px auto 0px auto;
    width: 90%;
  }

  #header img {
    display: none;
  }
}
/* ------------------------------ About CSS ----------------------------- */
#about {
  scroll-margin-top: 150px;
}
#about .about-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.about-header {
  margin: 5% auto;
  font-size: 3em;
}

#about .about-content img {
  width: 30%;
  border-radius: 0.5em;
}

#about .about-content div {
  width: 40%;
  padding: 0px 3%;
  display: flex;
  flex-direction: column;
}

#about p {
  font-size: 1.3em;
  line-height: 1.5em;
  padding-bottom: 6%;
  color: rgb(65, 65, 65);
  margin-top: 2%;
}

br {
  line-height: 0.2em;
}

#about .about-button {
  border: none;
  border-radius: 0.5em;
  background-color: dodgerblue;
  color: white;
  font-size: 1.3em;
  padding: 15px 30px;
  margin-top: 3%;
  width: 100%;
  text-align: center;
}

#about .about-button:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  #about .about-content img {
    width: 70%;
    border-radius: 0.5em;
  }

  #about .about-content div {
    width: 90%;
    padding: 0% 2%;
    margin-top: 5%;
  }
}
/*------------------------------Projects-----------------------------*/
#projects {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

#Projects {
  margin: 8% auto;
  scroll-margin-top: 150px;
}

#Projects .project-header {
  margin: 5% auto;
  font-size: 3em;
  text-align: center;
}

.projects-div {
  width: 30%;
  height: auto;
  text-align: center;
  align-self: flex-start;
  padding: 1%;
  border-radius: 0.5em;
  margin: 0% 1.5% 2% 1.5%;
  box-shadow: 1px 1px 5px silver;
}

.projects-div a {
  text-decoration: underline;
  font-size: larger;
}

.projects-headers {
  font-size: x-large;
}

.projects-img {
  display: block;
  width: 70%;
  height: 300px;
  object-fit: cover;
  margin: 3% auto;
  border-radius: 0.5em;
  object-position: top center;
}

.projects-description {
  padding: 2% 15%;
  line-height: 1.2em;
  text-align: justify;
  font-size: 1.1em;
}

.projects-tools {
  padding: 0% 15%;
  line-height: 1.2em;
  text-align: justify;
  font-size: 1.1em;
}

@media screen and (max-width: 680px) {
  .projects-div {
    width: 80%;
  }
}

/* ----------------------------- SKILLS ----------------------------- */
#skills {
  scroll-margin-top: 150px;
  margin: 8% auto 8% auto;
}

.skills-header {
  text-align: center;
  margin: 5% auto;
  font-size: 3em;
}

.skills-div {
  width: 95%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
#skills img {
  object-fit: scale-down;
  width: 25%;
  height: 200px;
  margin: 2% 0% 4% 0%;
  padding: 2%;
}

@media screen and (max-width: 680px) {
  #skills {
    margin: 20% auto 12% auto;
  }

  .skills-div {
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
    align-items: center;
  }

  .skills-div::-webkit-scrollbar {
    display: none;
  }

  #skills img {
    width: auto;
    object-fit: cover;
  }
}
/* --------------------------------------- Services ---------------------------------------- */
#services {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  margin: auto;
}

#services div {
  text-align: center;
}

#services div p {
  padding: 12px;
  font-size: 1.1em;
  color: gray;
}

.service-header {
  font-size: 3em;
  text-align: center;
}

.services-card {
  width: 30%;
  height: 500px;
  border-radius: 0.5em;
  border-top: 3px solid #2541b2;
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: 50px;
}

#services h4 {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  font-size: 1.3em;
}

.card-inner-content {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pricing-div {
  margin-top: 30px;
}

.service-card-button {
  color: white;
  background-color: #2541b2;
  padding: 10px 30px;
  border-radius: 0.5em;
  font-size: 1.2em;
  border: none;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
}

/* ---------- Contact Me ---------- */
#contact-me {
  margin: 8% auto 8% auto;
  scroll-margin-top: 110px;
}

.contact-header {
  text-align: center;
  margin: 5% auto;
  font-size: 3em;
}

.contact-div {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 3% auto;
  flex-wrap: wrap;
}

#contact-info {
  width: 45%;
}

#contact-info h4 {
  font-size: 1.4em;
  padding: 1% 0%;
}

#contact-info p {
  font-size: 1.1em;
  line-height: 2em;
}

#contact-form {
  width: 45%;
  text-align: center;
}

input[type="text"],
input[type="email"] {
  padding: 15px;
  margin-bottom: 30px;
  border-radius: 0.5em;
}

.inputs-line-1 {
  width: 44%;
}

.inputs-line-1:first-child {
  margin-right: 1%;
}

.inputs-line-1:last-child {
  margin-left: 1%;
}

.inputs {
  width: 90%;
}

textarea {
  width: 90%;
  height: 200px;
  border-radius: 0.5em;
}

#contact-form .submit-btn {
  margin: 1% 0%;
  color: white;
  background-color: #2541b2;
  padding: 10px 30px;
  border-radius: 0.5em;
  font-size: 1.2em;
  border: none;
}

.email-alert-success {
  background-color: aliceblue;
  color: #2541b2;
  width: 80%;
  margin: auto;
  border-radius: 0.5em;
  padding: 15px;
  margin-bottom: 30px;
}

.email-alert-failure {
  background-color: lightpink;
  color: crimson;
  width: 80%;
  margin: auto;
  border-radius: 0.5em;
  padding: 15px;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  #contact-info {
    width: 100%;
    text-align: center;
    padding: 2%;
  }

  #contact-form {
    width: 100%;
    margin-top: 6%;
  }

  #contact-info p {
    font-size: 1.1em;
    line-height: 2em;
  }

  textarea {
    height: 100px;
  }
}
